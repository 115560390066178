export function allPokemonNames() {
  return [
    "Bulbasaur",
    "Ivysaur",
    "Venusaur",
    "Charmander",
    "Charmeleon",
    "Charizard",
    "Squirtle",
    "Wartortle",
    "Blastoise",
    "Caterpie",
    "Metapod",
    "Butterfree",
    "Weedle",
    "Kakuna",
    "Beedrill",
    "Pidgey",
    "Pidgeotto",
    "Pidgeot",
    "Rattata",
    "Raticate",
    "Spearow",
    "Fearow",
    "Ekans",
    "Arbok",
    "Pikachu",
    "Raichu",
    "Sandshrew",
    "Sandslash",
    "Nidoran♀",
    "Nidorina",
    "Nidoqueen",
    "Nidoran♂",
    "Nidorino",
    "Nidoking",
    "Clefairy",
    "Clefable",
    "Vulpix",
    "Ninetales",
    "Jigglypuff",
    "Wigglytuff",
    "Zubat",
    "Golbat",
    "Oddish",
    "Gloom",
    "Vileplume",
    "Paras",
    "Parasect",
    "Venonat",
    "Venomoth",
    "Diglett",
    "Dugtrio",
    "Meowth",
    "Persian",
    "Psyduck",
    "Golduck",
    "Mankey",
    "Primeape",
    "Growlithe",
    "Arcanine",
    "Poliwag",
    "Poliwhirl",
    "Poliwrath",
    "Abra",
    "Kadabra",
    "Alakazam",
    "Machop",
    "Machoke",
    "Machamp",
    "Bellsprout",
    "Weepinbell",
    "Victreebel",
    "Tentacool",
    "Tentacruel",
    "Geodude",
    "Graveler",
    "Golem",
    "Ponyta",
    "Rapidash",
    "Slowpoke",
    "Slowbro",
    "Magnemite",
    "Magneton",
    "Farfetch'd",
    "Doduo",
    "Dodrio",
    "Seel",
    "Dewgong",
    "Grimer",
    "Muk",
    "Shellder",
    "Cloyster",
    "Gastly",
    "Haunter",
    "Gengar",
    "Onix",
    "Drowzee",
    "Hypno",
    "Krabby",
    "Kingler",
    "Voltorb",
    "Electrode",
    "Exeggcute",
    "Exeggutor",
    "Cubone",
    "Marowak",
    "Hitmonlee",
    "Hitmonchan",
    "Lickitung",
    "Koffing",
    "Weezing",
    "Rhyhorn",
    "Rhydon",
    "Chansey",
    "Tangela",
    "Kangaskhan",
    "Horsea",
    "Seadra",
    "Goldeen",
    "Seaking",
    "Staryu",
    "Starmie",
    "Mr. Mime",
    "Scyther",
    "Jynx",
    "Electabuzz",
    "Magmar",
    "Pinsir",
    "Tauros",
    "Magikarp",
    "Gyarados",
    "Lapras",
    "Ditto",
    "Eevee",
    "Vaporeon",
    "Jolteon",
    "Flareon",
    "Porygon",
    "Omanyte",
    "Omastar",
    "Kabuto",
    "Kabutops",
    "Aerodactyl",
    "Snorlax",
    "Articuno",
    "Zapdos",
    "Moltres",
    "Dratini",
    "Dragonair",
    "Dragonite",
    "Mewtwo",
    "Mew",
    "Chikorita",
    "Bayleef",
    "Meganium",
    "Cyndaquil",
    "Quilava",
    "Typhlosion",
    "Totodile",
    "Croconaw",
    "Feraligatr",
    "Sentret",
    "Furret",
    "Hoothoot",
    "Noctowl",
    "Ledyba",
    "Ledian",
    "Spinarak",
    "Ariados",
    "Crobat",
    "Chinchou",
    "Lanturn",
    "Pichu",
    "Cleffa",
    "Igglybuff",
    "Togepi",
    "Togetic",
    "Natu",
    "Xatu",
    "Mareep",
    "Flaaffy",
    "Ampharos",
    "Bellossom",
    "Marill",
    "Azumarill",
    "Sudowoodo",
    "Politoed",
    "Hoppip",
    "Skiploom",
    "Jumpluff",
    "Aipom",
    "Sunkern",
    "Sunflora",
    "Yanma",
    "Wooper",
    "Quagsire",
    "Espeon",
    "Umbreon",
    "Murkrow",
    "Slowking",
    "Misdreavus",
    "Unown",
    "Wobbuffet",
    "Girafarig",
    "Pineco",
    "Forretress",
    "Dunsparce",
    "Gligar",
    "Steelix",
    "Snubbull",
    "Granbull",
    "Qwilfish",
    "Scizor",
    "Shuckle",
    "Heracross",
    "Sneasel",
    "Teddiursa",
    "Ursaring",
    "Slugma",
    "Magcargo",
    "Swinub",
    "Piloswine",
    "Corsola",
    "Remoraid",
    "Octillery",
    "Delibird",
    "Mantine",
    "Skarmory",
    "Houndour",
    "Houndoom",
    "Kingdra",
    "Phanpy",
    "Donphan",
    "Porygon2",
    "Stantler",
    "Smeargle",
    "Tyrogue",
    "Hitmontop",
    "Smoochum",
    "Elekid",
    "Magby",
    "Miltank",
    "Blissey",
    "Raikou",
    "Entei",
    "Suicune",
    "Larvitar",
    "Pupitar",
    "Tyranitar",
    "Lugia",
    "Ho-oh",
    "Celebi",
    "Azurill",
    "Wynaut",
    "Ambipom",
    "Mismagius",
    "Honchkrow",
    "Bonsly",
    "Mime Jr.",
    "Happiny",
    "Munchlax",
    "Mantyke",
    "Weavile",
    "Magnezone",
    "Lickilicky",
    "Rhyperior",
    "Tangrowth",
    "Electivire",
    "Magmortar",
    "Togekiss",
    "Yanmega",
    "Leafeon",
    "Glaceon",
    "Gliscor",
    "Mamoswine",
    "Porygon-Z",
    "Treecko",
    "Grovyle",
    "Sceptile",
    "Torchic",
    "Combusken",
    "Blaziken",
    "Mudkip",
    "Marshtomp",
    "Swampert",
    "Ralts",
    "Kirlia",
    "Gardevoir",
    "Gallade",
    "Shedinja",
    "Kecleon",
    "Beldum",
    "Metang",
    "Metagross",
    "Bidoof",
    "Spiritomb",
    "Lucario",
    "Gible",
    "Gabite",
    "Garchomp",
    "Mawile",
    "Lileep",
    "Cradily",
    "Anorith",
    "Armaldo",
    "Cranidos",
    "Rampardos",
    "Shieldon",
    "Bastiodon",
    "Slaking",
    "Absol",
    "Duskull",
    "Dusclops",
    "Dusknoir",
    "Wailord",
    "Arceus",
    "Turtwig",
    "Grotle",
    "Torterra",
    "Chimchar",
    "Monferno",
    "Infernape",
    "Piplup",
    "Prinplup",
    "Empoleon",
    "Nosepass",
    "Probopass",
    "Honedge",
    "Doublade",
    "Aegislash",
    "Pawniard",
    "Bisharp",
    "Luxray",
    "Aggron",
    "Flygon",
    "Milotic",
    "Salamence",
    "Klinklang",
    "Zoroark",
    "Sylveon",
    "Kyogre",
    "Groudon",
    "Rayquaza",
    "Dialga",
    "Palkia",
    "Giratina",
    "Regigigas",
    "Darkrai",
    "Genesect",
    "Reshiram",
    "Zekrom",
    "Kyurem",
    "Roserade",
    "Drifblim",
    "Lopunny",
    "Breloom",
    "Ninjask",
    "Banette",
    "Rotom",
    "Reuniclus",
    "Whimsicott",
    "Krookodile",
    "Cofagrigus",
    "Galvantula",
    "Ferrothorn",
    "Litwick",
    "Lampent",
    "Chandelure",
    "Haxorus",
    "Golurk",
    "Pyukumuku",
    "Klefki",
    "Talonflame",
    "Mimikyu",
    "Volcarona",
    "Deino",
    "Zweilous",
    "Hydreigon",
    "Latias",
    "Latios",
    "Deoxys",
    "Jirachi",
    "Nincada",
    "Bibarel",
    "Riolu",
    "Slakoth",
    "Vigoroth",
    "Wailmer",
    "Shinx",
    "Luxio",
    "Aron",
    "Lairon",
    "Trapinch",
    "Vibrava",
    "Feebas",
    "Bagon",
    "Shelgon",
    "Klink",
    "Klang",
    "Zorua",
    "Budew",
    "Roselia",
    "Drifloon",
    "Buneary",
    "Shroomish",
    "Shuppet",
    "Solosis",
    "Duosion",
    "Cottonee",
    "Sandile",
    "Krokorok",
    "Yamask",
    "Joltik",
    "Ferroseed",
    "Axew",
    "Fraxure",
    "Golett",
    "Fletchling",
    "Fletchinder",
    "Larvesta",
    "Stunfisk",
    "Sableye",
    "Venipede",
    "Whirlipede",
    "Scolipede",
    "Tyrunt",
    "Tyrantrum",
    "Snorunt",
    "Glalie",
    "Froslass",
    "Oricorio Baile Style",
    "Oricorio Pom-Pom Style",
    "Oricorio Pa'u Style",
    "Oricorio Sensu Style",
    "Trubbish",
    "Garbodor",
    "Carvanha",
    "Sharpedo",
    "Phantump",
    "Trevenant",
    "Noibat",
    "Noivern",
    "Swablu",
    "Altaria",
    "Goomy",
    "Sliggoo",
    "Goodra",
    "Regirock",
    "Regice",
    "Registeel",
    "Necrozma",
    "Stufful",
    "Bewear",
    "Dhelmise",
    "Mareanie",
    "Toxapex",
    "Hawlucha",
    "Cacnea",
    "Cacturne",
    "Sandygast",
    "Palossand",
    "Amaura",
    "Aurorus",
    "Rockruff",
    "Lycanroc Midday Form",
    "Lycanroc Midnight Form",
    "Meloetta Aria Form",
    "Meloetta Pirouette Form",
    "Cresselia",
    "Bruxish",
    "Necrozma Ultra"
  ];
}

export function getTypes(headType1, headType2, bodyType1, bodyType2) {
  if (bodyType2 != null && headType1 !== bodyType2) return [headType1, bodyType2];
  if (bodyType1 != null && headType1 !== bodyType1) return [headType1, bodyType1];
  return [headType1];
}

export function getName(headName, headNameSeparatorIndex, bodyName, bodyNameSeparatorIndex) {
  const headIndex = parseInt(headNameSeparatorIndex.split(".")[0]);
  const headPart = headName.slice(0, headIndex);

  const bodyNameSeparatorIndexParts = bodyNameSeparatorIndex.split(".");
  const bodyIndex = parseInt(
    bodyNameSeparatorIndexParts.length > 1
      ? bodyNameSeparatorIndex.split(".")[1]
      : bodyNameSeparatorIndex.split(".")[0],
  );
  const bodyPart = bodyName.slice(bodyIndex);

  if (headPart.charAt(headPart.length - 1) === bodyPart.charAt(0)) {
    return headPart + bodyPart.slice(1);
  } else {
    return headPart + bodyPart;
  }
}

export function getScore(score) {
  return Math.ceil(score / 2);
}
